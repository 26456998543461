<template>
    <div class="column-table" :style="style">
        <LabelButtonItem v-for="(item, idx) in list" :key="idx" :data="item" />
    </div>
</template>

<script>
import LabelButtonItem from "@/components/Cards/LabelButtonItem";

export default {
    name: "ColumnTable",
    components: {
        LabelButtonItem,
    },
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        flexBasis: {
            type: String,
            default: "50%",
        },
    },
    data() {
        return {
            style: {
                "--flex-basis": this.flexBasis,
            },
        };
    },
};
</script>

<style lang='scss' scoped>

.column-table {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    border-top: 1px solid $light_gray;
    border-left: 1px solid $light_gray;

    > div {
        flex-basis: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        border-bottom: 1px solid $light_gray;
        border-right: 1px solid $light_gray;

        @media (min-width: 992px) {
            flex-basis: var(--flex-basis);
        }
    }
}
</style>