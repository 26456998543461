<template>
	<Popup
		class="generate-payout"
		:modal-id="modalId"
		:on-close="onModalClose"
		size="lg"
		custom-class="gray-header"
		:toggle-status="toggleStatus"
		:x-close-btn="false"
	>
		<template v-slot:header>
			<div class="generate-payout__header">
				<div class="create-entity__header__title">{{ labels.generate_payout_table }}</div>
				<LabelInput :data="budget" font-family="'Oswald', sans-serif"/>
			</div>
		</template>

		<template v-slot:body>
			<div class="generate-payout__body">

				<ColumnTable :list="tableData" flex-basis="50%"/>

				<div class="date">
					<LabelInput type="date" :data="date.from"/>
					<LabelInput type="date" :data="date.to"/>
				</div>

			</div>
		</template>

		<template v-slot:footer>
			<Button :label="labels.lorem" variant="btn-outline-purple" style="fontWeight: 600" :action="() => {}" />
			<Button :label="labels.lorem" variant="btn-purple" style="fontWeight: 600" :action="() => {}" />
			<Button :label="labels.close" variant="btn-outline-purple" data-dismiss="modal" style="fontWeight: 600" :action="onModalClose" />
		</template>

	</Popup>
</template>

<script>
import Popup from '@/components/Popups/Popup';
import Button from '@/components/Buttons/Button';
import LabelInput from '@/components/Input/LabelInput';
import ColumnTable from '@/components/Table/ColumnTable';
import { mapGetters } from 'vuex';

export default {
	name: 'GeneratePayout',
	components: {
		Popup,
		Button,
		LabelInput,
		ColumnTable,
	},
	props: {
		modalId: {
			type: String,
			default: 'user-generate-payout',
			// required: true
		},
		routeBack: {
			type: Object,
			default: () => {}
		},
	},
	data() {
		return {
			toggleStatus: false,
			budget: {
				value: '',
				label: 'Budget',
			},
			date: {
				from: {
					value: '',
					label: 'From',
				},
				to: {
					value: '',
					label: 'To',
				},
			},
			tableData: [
				{ labels: ['Content Type xy', 'xx weight'] },
				{ labels: ['Content Type xy', 'xx weight'] },
				{ labels: ['Content Type xy', 'xx weight'] },
				{ labels: ['Content Type xy', 'xx weight'] },
			],
		}
	},
	methods: {
		onModalClose() {
			if ( this.routeBack && Object.keys( this.routeBack ).length ) {
				this.$router.push( this.routeBack );
			} else {
				this.$router.go(-1);
			}
		},
	},
	computed: {
		...mapGetters( 'helper', {
			hasPermissions: 'hasPermissions',
			labels: 'labels'
		}),
		isAllowed() {
			return this.hasPermissions(['super', 'system']);
		}
	},
	beforeMount() {
		// Redirect if user don't have permissions
		if (!this.isAllowed) {
			this.$router.push( {name: 'dashboard'} );
		}
	},
	mounted() {
		this.toggleStatus = true;

		let element = document.querySelector('.modal-backdrop');
		if ( element ) {
            element.remove();
            document.querySelector('body').classList.remove('modal-open');
        }
        
		this.$(`#${this.modalId}`).modal('show');
	},
	beforeDestroy() {
		this.toggleStatus = false;
	},
}
</script>

<style lang='scss' scoped>

.generate-payout {

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	&__body {

		.date {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			padding: 0 15px;

			> div {
				margin-bottom: 15px;
			}

			@media (min-width: 1200px) {
				padding: 0 25px;
			}
		}
	}

	::v-deep .modal-content {

		.modal-header {
			padding-top: 8px;
			padding-bottom: 8px;
		}

		.modal-body {
			padding: 0;
		}
	}
}

</style>