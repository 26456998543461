<template>
  <div class="lable-button-item" :style="style">
    <div
      class="lable-button-item__label"
      v-if="data.preTitle || data.label"
      :style="getStyle('label')"
    >
      <router-link v-if="data.link" :to="data.link">
        <span class="pre-title" v-if="data.preTitle">
          {{ data.preTitle }}
        </span>
        <template v-if="data.label && typeof data.label === 'string'">
          <span>
            <div class="content" v-html="data.label"></div>
          </span>
        </template>
      </router-link>
      <template v-else>
        <span class="pre-title" v-if="data.preTitle">
          {{ data.preTitle }}
        </span>
        <template v-if="data.label && typeof data.label === 'string'">
          <div class="content" v-html="data.label"></div>

          <div
            v-if="displayReviewStatus && data.review && data.review.status == 1"
            class="review-requested"
          >
            <span>{{ labels.review_requested }}</span>
          </div>

          <div
            v-if="displayReviewStatus && data.review && data.review.status == 2"
            class="review"
          >
            <span>{{ labels.in_review }}</span>
          </div>
        </template>
      </template>
    </div>

    <!--
		Multiple columns of labels can be either an array of labels or an array of objects with labels and styles.
		Examples:
		either - data.labels: ['25% depth', 'XX dicho', 'XX %']
		or - data.labels: [
			{ label: '25% depth', style: {} },
			{ label: 'XX dicho', style: {} },
			{ label: 'XX %', style: {} },
		]
		-->
    <div
      class="lable-button-item__label-columns"
      v-if="data.labels && Array.isArray(data.labels)"
    >
      <div
        class="label"
        :key="idx"
        v-for="(item, idx) in data.labels"
        :style="item && item.style ? item.style : ''"
      >
        <template v-if="typeof item === 'string'">
          {{ item }}
        </template>
        <template v-else-if="item && item.label">
          {{ item.label }}
        </template>
      </div>
    </div>

    <!--
		List of buttons and icons. The sequence depends on the order of the buttons and icons in the array.
		Example:
		data.actions: [
			{ type: 'button', label: 'Copy', variant: 'btn-navy', action:()=>{} },
			{ type: 'icon', icon:'custom-external-link', action:()=>{} },
		]
		-->
    <template v-if="data.actions">
      <div
        :class="['lable-button-item__btns', item.type]"
        v-for="(item, idx) in data.actions"
        :key="idx"
        :style="getStyle(item.type, true, item.style)"
      >
        <router-link
          v-if="item.type === 'button'"
          :to="item.link ? item.link : ''"
        >
          <Button
            :style="item && item.style ? item.style : ''"
            :variant="item.variant"
            :label="item && item.label"
            :key="`btn-${data.label}-${idx}`"
            @click="item.action ? item.action(getId) : () => {}"
          />
        </router-link>
        <router-link
          v-if="item.type === 'icon'"
          :to="item.link ? item.link : ''"
          target="_blank"
        >
          <font-awesome-icon
            :style="item && item.style ? item.style : ''"
            :icon="item.icon"
            :key="`icon-${data.label}-${idx}`"
            @click="item.action ? item.action(getId) : () => {}"
          />
        </router-link>
        <div
          v-if="item.type === 'label'"
          :style="btnTypeLabelStyle(item.style, item.class)"
          :class="['btn-type-label', item.class]"
        >
          {{ item.label }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
const colors = require("/colors.config.json");
export default {
  name: "LabelButtonItem",
  components: {
    Button,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
    displayReviewStatus: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getId() {
      if (this.data._id) return this.data._id;
      if (this.data.id) return this.data.id;
      return Date.now();
    },
    style() {
      let output = {
        "--min-width": "190px",
      };
      if (this.settings?.minWidth)
        output["--min-width"] = this.settings.minWidth;
      return output;
    },
  },

  methods: {
    getStyle(key, isActions, specificStyle) {
      if (specificStyle) return specificStyle;
      if (
        isActions &&
        this.settings &&
        this.settings.actions &&
        this.settings.actions[key] &&
        this.settings.actions[key].style
      ) {
        return this.settings.actions[key].style;
      } else if (
        this.settings &&
        this.settings[key] &&
        this.settings[key].style
      ) {
        return this.settings[key].style;
      }
    },
    btnTypeLabelStyle(style, itemClass) {
      let output = {
        "--label-color": `${colors.navy}`,
        "--label-font-size": "1rem",
        "--label-font-weight": "600",
      };
      if (itemClass && itemClass.includes("bg-label")) {
        output["--label-bg-color"] = `${colors.navy}`;
        output["--label-color"] = `${colors.white}`;
        output["--label-font-weight"] = "400";
      }
      if (style && style.bgColor) output.bgColor = style.bgColor;
      if (style && style.color) output.color = style.color;
      if (style && style.fontSize) output.fontSize = style.fontSize;
      if (style && style.fontWeight) output.fontWeight = style.fontWeight;
      return output;
    },
  },
};
</script>

<style lang="scss" scoped>
.lable-button-item__label {
  a {
    text-decoration: none;
  }
}
.lable-button-item {
  .review span:last-child {
    border-color: rgb(168 122 59);
    background: #cb695869;
  }

  .review span {
    padding: 2px 5px;
    background: rgb(103 177 220 / 41%);
    border-radius: 5px;
    border: 1px solid rgba(103 177 220 / 100%);
    margin-right: 5px;
  }

  .review-requested span:last-child {
    border-color: rgb(168 122 59);
    background: #d3af0e69;
  }

  .review-requested span {
    padding: 2px 5px;
    background: rgb(103 177 220 / 41%);
    border-radius: 5px;
    border: 1px solid rgba(103 177 220 / 100%);
    margin-right: 5px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  min-width: var(--min-width);

  &:not(:last-child) {
    border-bottom: 1px solid $light_gray;
  }

  &__label {
    flex: 1 1 auto;
    font-size: 0.875rem;
    color: $dark_text;

    .pre-title {
      color: black;
      font-weight: 600;
    }

    .content {
      ::v-deep {
        *:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  &__label-columns {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      &:first-child {
        margin-right: 10px;
      }
    }
  }

  &__btns {
    flex: 0 1 20%;

    &.button,
    &.label {
      display: flex;
      justify-content: flex-end;
      min-width: max-content;
    }

    &.icon {
      display: flex;
      justify-content: center;
      min-width: 50px;
    }

    button {
      padding: 5px 15px;
    }
    svg {
      color: $light_text;
    }

    .btn-type-label {
      display: inline-block;
      color: var(--label-color);
      font-size: var(--label-font-size);
      font-weight: var(--label-font-weight);
      padding: 4px 0.809rem;
      border-radius: 5px;

      &.bg-label {
        background-color: var(--label-bg-color);
        padding: 4px 15px;
      }
    }

    // &:not(:first-child) {
    // 	margin-left: 15px;
    // }
  }
}
</style>
