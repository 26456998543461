<template>
  <div :class="['base-card', { none: !baseCard }]" :style="style">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BaseCard",
  data() {
    return {
      style: {
        "--padding": this.padding,
        "--margin": this.margin,
        "--overflow": this.overflow,
        "--border-radius": this.borderRadius,
        ...this.styles,
      },
    };
  },
  props: {
    styles: {
      type: Object,
      default: () => {},
    },
    padding: {
      type: String,
      default: "25px",
    },
    margin: {
      type: String,
      default: "0",
    },
    overflow: {
      type: String,
      default: "hidden",
    },
    borderRadius: {
      type: String,
      default: "4px",
    },
    baseCard: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.base-card {
  &:not(.none) {
    background-color: $white;
    -webkit-box-shadow: 3px 3px 5px 1px rgb(0 0 0 / 10%);
    -moz-box-shadow: 3px 3px 5px 1px rgb(0 0 0 / 10%);
    box-shadow: 3px 3px 5px 1px rgb(0 0 0 / 10%);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    padding: var(--padding);
    margin: var(--margin);
    overflow: var(--overflow);
    border-radius: var(--border-radius);

    &:hover {
      -webkit-box-shadow: 3px 3px 8px 2px rgb(0 0 0 / 11%);
      -moz-box-shadow: 3px 3px 8px 2px rgb(0 0 0 / 11%);
      box-shadow: 3px 3px 8px 2px rgb(0 0 0 / 11%);
    }
  }
}
</style>
